<div class="custom-form">
    <div class="flex-container text-center" appResizeDialog>
        <h1>Identificarse</h1>
    </div>
    
    <form novalidate #form="ngForm" (ngSubmit)="onSubmit(form.value)">

        <div class="text-center">
            <mat-label>Contraseña módulo Administrador</mat-label>
            <input #pwd class="form-control text-center" ngModel matInput required name="pwd"/>
        </div>

        <div class="text-center">
            <button mat-raised-button color="accent"
                    [disabled]="!pwd.value" type="submit">Enviar</button>
        </div>
        
    </form>
</div>