<div class="textos">

    <h1 class="textos">Carta de Navidad El Romero en casa</h1>
    <p>Esta carta estará solo disponible para recoger en nuestro local los </p>
    <p>días 24, 25, 31 de Diciembre y 1 de Enero, previa reserva</p>
    <p>Hora de recogida: 24 y 31 hasta las 18:00h, 25 y 1 a las 13:00h</p>
    <h2 class="textos">Aperitivos</h2>


    <p>Nuestra ensaladilla con gambón (200g aprox) - 20.00€</p>
    <p>Salmón marinado en casa - 20.00€</p>
    <p>Paté al Oporto - 10.00€</p>
    <p>Paté en crôute- 15.00€</p>
    <p>Ostra en escabeche (unidad) - 6.00€</p>
    <p>Salpicón de bogavante (200g) - Según mercado</p>
    <p>Pastel de cabracho - 15.00€</p>
    <p>Canapés salados (12 u) - 20.00€</p>
    <p>Ensalada de perdiz escabechada, granada y manzana - 19.50€</p>

    <h2 class="textos">Principales:</h2>

    <p>Salmón wellington (lomo) - 90.00€</p>
    <p>Pescado al horno - Según mercado</p>
    <p>Marisco - Según mercado</p>
    <p>Jamón asado de Navidad (ración)- 18.00€</p>
    <p>Pollo relleno (ración/pieza) - 20.00€/70.00€</p>
    <p>Paletilla de Cordero lechal de Burgos IGP (unidad) - Consultar</p>
    <p>Osobucco de ternera gallega (pieza) - Consultar</p>
    <p>Roast Beef de ternera galleg IGP (ración/pieza) - 19.00€/Consultar</p>

    <h2 class="textos">Guarniciones (200g - 7.00€):</h2>

    <p>Patatas y chalotas risoladas</p>
    <p>Zanahorias, chirivías asadas y especiadas</p>
    <p>Verduras de invierno salteadas</p>
    <p>Puré de patata Robuchon</p>
    <p>Calabaza asada</p>
    <p>Coles de bruselas con panceta ibérica</p>

    <h2 class="textos">Postres:</h2>

    <p>Nuestras tartas por ración - 5.00€</p>
    <p>Mini cheesecake - 10.00€</p>
    <p>Tarta Paulova - 10.00€</p>
    <p>Crema de limón - 6.00€</p>
    <p>Brownie de chocolate - 5.00€<p>

</div>


<!-- <div class="textos">

    <h1 class="textos">Menú de Fin de Año</h1>

    <h2 class="textos">Empezamos</h2>

    <p>Pan de A Moa, mantequilla tradicional y caviar</p>
    <p>Maremoto: nuestra versión del salpicón de marisco</p>
    <p>Paté en crôute, ensalada de hojas tiernas y salsa ligera</p>
    <p>Huevo parmentier</p>

    <h2 class="textos">Continuamos</h2>

    <p>Alcachofa, jugo de ave y trufa</p>
    <p>Pescado del día</p>
    <p>Galo de corral guisado con ostras</p>

    <h2 class="textos">Terminamos</h2>

    <p>Queso trufado</p>
    <p>Leche, cacao, avellanas y azúcar</p>

    <h2 class="textos">Recibimos el año</h2>

    <p>Uvas de la suerte y champán Perrier Jouët</p>

    <p>Precio: 90.00€/persona - no incluye bodega</p>

</div> -->
