<div fxLayout="row" fxLayoutAlign="center center">
    <h2 style="text-align: center;"class="gallery-text">Galería de Imágenes</h2>
  </div>
  
  <mat-grid-list [cols]="columns" gutterSize="10px" rowHeight="3:2">
    <mat-grid-tile *ngFor="let item of gallery">
      <img class="gallery-small-image"
           [src]="item.src"
           [alt]="item.alt"
           (click)="openDialog(item.position)">
    </mat-grid-tile>
  </mat-grid-list>
<img style="display:block; margin:auto; height: 30%; width: 30%;" src="../../../assets/img/logo.png" alt="Logo">