import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EmailService } from './services/email.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BookingComponent } from './components/booking/booking.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GalleryService } from './services/gallery.service';
import { MenuComponent } from './components/menu/menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { FirebaseService } from './services/firebase.service';
import { environment } from 'src/environments/environment';
import { MatSelectModule } from '@angular/material/select';
import { PrivacyTermsComponent } from './components/privacy-terms/privacy-terms.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PrivacyTextComponent } from './components/privacy-text/privacy-text.component';
import { BrunchComponent } from './components/brunch/brunch.component';
import { MenuXmasComponent } from './components/menu-xmas/menu-xmas.component';
import { UpdateMenuComponent } from './components/update-menu/update-menu.component';
import { AdminManagementComponent } from './components/admin-management/admin-management.component';
import { MenuElementComponent } from './components/menu-element/menu-element.component';
import { ArraySortPipe } from './utils/array-sort.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NewYearMenuComponent } from './components/new-year-menu/new-year-menu.component';
import { DrinkComponent } from './components/drink/drink.component';
import { UpdateDrinkComponent } from './components/update-drink/update-drink.component';
import { DrinkElementComponent } from './components/drink-element/drink-element.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomePageComponent,
    ContactUsComponent,
    BookingComponent,
    AboutUsComponent,
    PopUpComponent,
    GalleryComponent,
    GalleryModalComponent,
    MenuComponent,
    PrivacyTermsComponent,
    PrivacyTextComponent,
    BrunchComponent,
    MenuXmasComponent,
    UpdateMenuComponent,
    AdminManagementComponent,
    MenuElementComponent,
    ArraySortPipe,
    NewYearMenuComponent,
    DrinkComponent,
    UpdateDrinkComponent,
    DrinkElementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatDividerModule,
    AngularFireDatabaseModule,
    AngularFireModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule
  ],
  providers: [
    EmailService,
    GalleryService,
    FirebaseService,
    {provide: MAT_DATE_LOCALE, useValue: 'es-GB'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
