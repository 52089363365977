import { Component, OnInit } from '@angular/core';
import { MenuModel } from 'src/app/model/menu.model';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  dataSourceEntrantes: MenuModel[];
  dataSourcePrincipales: MenuModel[];
  dataSourcePostres: MenuModel[];
  dataSourceRacion: MenuModel[];

  constructor(private dbService: FirebaseService) { }

  ngOnInit(): void {
    this.dbService.getMenuList().snapshotChanges()
      .subscribe( item => {
        this.dataSourceEntrantes = [];
        this.dataSourcePrincipales = [];
        this.dataSourcePostres = [];
        this.dataSourceRacion = [];
        item.forEach( element => {
          let x = element.payload.toJSON();
          x['key'] = element.key;
          if (x['clase'] === 'entrante') {
            this.dataSourceEntrantes.push(x as MenuModel);
          } else if (x['clase'] === 'principal') {
            this.dataSourcePrincipales.push(x as MenuModel);
          } else if (x['clase'] === 'postre') {
            this.dataSourcePostres.push(x as MenuModel);
          } else if (x['clase'] === 'racion') {
            this.dataSourceRacion.push(x as MenuModel);
          }
        });
    });
  }

  splitDescription(alergies: string) {
    return alergies.split(',');
  }

}
