import { Component } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from '../pop-up/pop-up.component';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrivacyTextComponent } from '../privacy-text/privacy-text.component';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent {
  day;

  horas = ['13.30', '13.45', '14.00', '14.15', '14.30', '14.45', '15.00', '21.00', '21.15', '21.30', '21.45',
   '22.00', '22.15', '22.30', '22.45'];

  horasSabado = ['10.30 - Brunch', '10.45 - Brunch', '11.00 - Brunch', '11.15 - Brunch', '11.30 - Brunch', '11.45 - Brunch',
  '12.00 - Brunch', '12.15 - Brunch', '12.30 - Brunch',
  '13.30', '13.45', '14.00', '14.15', '14.30', '14.45', '15.00', '21.00', '21.15', '21.30', '21.45',
  '22.00', '22.15', '22.30', '22.45'];

  horasDomingo = ['10.30 - Brunch', '10.45 - Brunch', '11.00 - Brunch', '11.15 - Brunch', '11.30 - Brunch', '11.45 - Brunch',
  '12.00 - Brunch', '12.15 - Brunch', '12.30 - Brunch',
  '13.30', '13.45', '14.00', '14.15', '14.30', '14.45', '15.00'];

  constructor(private connectionService: EmailService, public dialog: MatDialog,
              private dbService: FirebaseService, private snackBar: MatSnackBar) {
                this.openSnackBar();
  }

  openDialog() {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: 'reserva'}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onSubmit(form) {
    console.log(form);
    this.dbService.insertBooking(form);
    this.openDialog();
  }

  obtainHours(date) {
    console.log(date);
    const day = new Date(date);
    const day2 = day.getDay();

    if (day2 === 0) {
      this.horas = this.horasDomingo;
    }
    if (day2 === 6) {
      this.horas = this.horasSabado;
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(PrivacyTextComponent, {
      duration: 3000
    });
  }

}
