<div class="textos">
    <h2 class="textos">Añadir un cóctel a la carta
        <button mat-icon-button>
            <mat-icon style="color: green;" (click)="openAddMod(newRecord)">add</mat-icon>
        </button>
    </h2>
    <h2 class="textos">Cócteles</h2>
    <div *ngFor="let element of dataSource | sort:'indice'">
        <p class="textos">{{element.nombre}} -> {{element.precio}} - {{element.ingredientes}}

            <button mat-icon-button (click)="openAddMod(element)">
                <mat-icon style="color: green;">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteElement(element.key)">
                <mat-icon style="color: green;">clear</mat-icon>
            </button>
        </p>
    </div>

</div>