<div>
    <h1 *ngIf="from==='reserva'" class="textos">
        ¡Muchas gracias!, contactaremos con usted lo antes posible para confirmar su reserva.
    </h1>
    <h1 *ngIf="from==='contacto'" class="textos">
        ¡Muchas gracias!, contactaremos con usted lo antes posible.
    </h1>
    <h1 *ngIf="from==='menuAdd'" class="textos">
        Plato añadido correctamente a la carta!
    </h1>
    <h1 *ngIf="from==='menuMod'" class="textos">
        Plato modificado correctamente!
    </h1>
    <h1 *ngIf="from==='menuDelete'" class="textos">
        Plato eliminado correctamente de la carta!
    </h1>
    <h1 *ngIf="from==='drinkAdd'" class="textos">
        Cóctel añadido correctamente a la carta!
    </h1>
    <h1 *ngIf="from==='drinkMod'" class="textos">
        Cóctel modificado correctamente!
    </h1>
    <h1 *ngIf="from==='drinkDelete'" class="textos">
        Cóctel eliminado correctamente de la carta!
    </h1>
    <h1 *ngIf="from==='adminManagementGood'" class="textos">
        Contraseña correcta!
    </h1>
    <h1 *ngIf="from==='adminManagementWrong'" class="textos">
        Contraseña incorrecta!
    </h1>
    <div>
        <button class="boton" mat-button mat-dialog-close> Aceptar </button>
    </div>
</div>