<div class="textos">
    <h1 class="textos">Carta</h1>

    <h2 class="textos" *ngIf="dataSourceEntrantes.length > 0">Para empezar:</h2>
    <div *ngFor="let element of dataSourceEntrantes | sort:'indice'">
        <p class="textos">{{element.nombre}} - {{element.precio}}
            <a *ngFor="let alergy of splitDescription(element.alergias)">
                <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
            </a>
        </p>
    </div>

    <h2 class="textos" *ngIf="dataSourcePrincipales.length > 0">Para continuar:</h2>
    <div *ngFor="let element of dataSourcePrincipales | sort:'indice'">
        <p class="textos">{{element.nombre}} - {{element.precio}}
            <a *ngFor="let alergy of splitDescription(element.alergias)">
                <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
            </a>
        </p>
    </div>

    <div *ngIf="dataSourceRacion.length > 0">
      <div *ngFor="let element of dataSourceRacion | sort:'indice'">
          <p class="textos">{{element.nombre}} - {{element.precio}}
              <a *ngFor="let alergy of splitDescription(element.alergias)">
                  <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
              </a>
          </p>
      </div>
    </div>

    <h2 class="textos" *ngIf="dataSourcePostres.length > 0">Para terminar:</h2>
    <div *ngFor="let element of dataSourcePostres | sort:'indice'">
      <p class="textos">{{element.nombre}} - {{element.precio}}
        <a *ngFor="let alergy of splitDescription(element.alergias)">
          <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
        </a>
      </p>
    </div>

    <br>
    <br>
    <br>
    <h2 class="textos">Menú "sinxelo":</h2>
    <h2 class="textos">5 pases a escoger en cocina - 45€/persona</h2>
    <h2 class="textos">Incremento de terraza - 15%</h2>
    <!--h2 class="textos"> También tenemos por encargo: </h2>
    <p class="textos">Pescados al horno</p>
    <p class="textos">Empanadas</p>
    <h2 class="textos">Dulces:</h2>
    <p class="textos">Toda repostería para llevar: raciones de tarta, brownie, galletas, tartaletas,...</p-->

</div>
