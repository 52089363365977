import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuModel } from 'src/app/model/menu.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PopUpComponent } from '../pop-up/pop-up.component';

@Component({
  selector: 'app-menu-element',
  templateUrl: './menu-element.component.html',
  styleUrls: ['./menu-element.component.css']
})
export class MenuElementComponent implements OnInit {
  record: MenuModel;
  clases = ['racion', 'entrante', 'principal', 'postre'];

  constructor(@Inject(MAT_DIALOG_DATA) data, private dbService: FirebaseService, public dialog: MatDialog) {
    this.record = data.record;

    if (this.record === undefined) {
      this.record = new MenuModel();
      this.record.nombre = '';
      this.record.alergias = '';
      this.record.clase = '';
      this.record.precio = '';
      this.record.indice = 0;
    } else {
      this.record.alergias = '';
    }
   }

  ngOnInit(): void {
  }

  addAlergia(check, alergia) {
    let separator = '';
    if (this.record.alergias !== '') {
      separator = ',';
    }
    if (check) {
      this.record.alergias = this.record.alergias + separator + alergia;
    } else {
      this.record.alergias = this.record.alergias.replace(separator + alergia, '');
    }
  }
  onSubmit(form) {
    form.key = this.record.key;
    form.alergy = this.record.alergias;
    if (this.record.key === undefined || this.record.key === null) {
      this.dbService.insertMenu(form);
      this.openDialog('menuAdd');
    } else {
      this.dbService.updateMenu(form);
      this.openDialog('menuMod');
    }

  }

  openDialog(from_: string) {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: from_}});

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
    });
  }

}
