<div class="textos">
    <h1 class="textos">Nuestro Brunch</h1>
    <p> ¡Podrás disfrutar del brunch los sábados, domingos y festivos con horario de 10.30 a 12.30 de la mañana! El orden por defecto será, primero los platos dulces y se terminará con el salado, ¡como tiene que ser! </p>
    <h2 class="textos">Para empezar</h2>
    <p> Una degustación de nuestra repostería hecha en el día, acompañada de un café o infusión.</p>
    <img src="../../../assets/img/brunch1.JPG" alt="" style="height: 30%; width: 40%;">
    <h2 class="textos">Terminamos con el salado</h2>
    <ul> Un plato principal a escoger entre carne o pescado, van cambiando a diario y un cóctel a escoger entre:
        <li>Copa de cava</li>
        <li>Cóctel Hugo</li>
        <li>Mimosa</li>
        <li>San Francisco</li>
        <li>Aperol Spritz</li>
    </ul>
    <p> Precio: 21,50€</p>
</div>

<!-- <h1 class="textos">Nuestro brunch (domingos de 10.30 a 12.30):</h1>
<h2 class="textos">Principales:</h2>
<p class="textos">Tosta de brioche con verduras de temporada, hueche poché y salmón marinado - 9.00€</p>
<p class="textos">Sandiwch en pan de brioche de lacón ahumado y queso del país - 7.00€</p>
<p class="textos">Bocadillo de solomillo de cerdo, queso del país, nuestro chutney de higos y hojas tiernas - 8.50€</p>
<p class="textos">Fish and chips - 9.00€</p> -->

<!-- <h2 class="textos">Para compartir:</h2>
<p class="textos">Tabla de quesos "de aquí y de allá" - 12.50</p>
<p class="textos">Paté al Oporto - 9.50€</p> -->


<!-- <div class="textos">
    <h1 class="textos">· Menú diario takeaway de Martes a Viernes</h1>
    <h2 class="textos">- Aperitivo del día</h2>
    <h2 class="textos">- Primer plato</h2>
    <h2 class="textos">- Segundo plato</h2>
    <h2 class="textos">- Nuestros postres</h2>
    <p>Precio: 14.00€ (pan incluido).</p>
    <img style="width: 30%; height: 30%;" src="../../../assets/img/logo.png" alt="">
</div> -->