import { Component } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { PopUpComponent } from '../pop-up/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  constructor(private connectionService: EmailService, private router: Router, public dialog: MatDialog) {
  }

  openDialog() {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: 'contacto'}});

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/']);
    });
  }

  onSubmit(form) {
    const formulario = {
      emailTo: 'elromerodegaleras@gmail.com',
      asunto: form.subject,
      mensaje: '<p> El cliente ' + form.name + ', con email: ' + form.email + ' ha enviado el siguiente mensaje: ' +
                '<p>"' + form.message + '".'
    };
    this.connectionService.sendEmail(formulario).subscribe(() => {
      this.openDialog();
    });
  }

}
