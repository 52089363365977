import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuModel } from 'src/app/model/menu.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MenuElementComponent } from '../menu-element/menu-element.component';
import { PopUpComponent } from '../pop-up/pop-up.component';

@Component({
  selector: 'app-update-menu',
  templateUrl: './update-menu.component.html',
  styleUrls: ['./update-menu.component.css']
})
export class UpdateMenuComponent implements OnInit {
  dataSourceEntrantes: MenuModel[];
  dataSourcePrincipales: MenuModel[];
  dataSourcePostres: MenuModel[];
  dataSourceRacion: MenuModel[];
  newRecord: MenuModel;

  constructor(public dialog: MatDialog, private dbService: FirebaseService) {
}

  ngOnInit(): void {
    this.dbService.getMenuList().snapshotChanges()
      .subscribe( item => {
        this.dataSourceEntrantes = [];
        this.dataSourcePrincipales = [];
        this.dataSourcePostres = [];
        this.dataSourceRacion = [];
        item.forEach( element => {
          let x = element.payload.toJSON();
          x['key'] = element.key;
          if(x['clase']==='entrante'){
            this.dataSourceEntrantes.push(x as MenuModel);
          }else if(x['clase']==='principal'){
            this.dataSourcePrincipales.push(x as MenuModel);
          }else if(x['clase']==='postre'){
            this.dataSourcePostres.push(x as MenuModel);
          }else if(x['clase']==='racion'){
            this.dataSourceRacion.push(x as MenuModel);
          }
        })
    });
  }

  openDialog(from_: string) {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: from_}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteElement(key: string) {
    this.dbService.deleteMenu(key);
    this.ngOnInit();
    this.openDialog('menuDelete');
  }

  openAddMod(element: MenuModel) {
    let dialogRef;

    dialogRef = this.dialog.open(MenuElementComponent, {
      data: {record: element}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  splitDescription(alergies: string) {
    return alergies.split(',');
  }
}
