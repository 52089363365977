import { Component, OnInit } from '@angular/core';
import { DrinkModel } from 'src/app/model/drink.model';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-drink',
  templateUrl: './drink.component.html',
  styleUrls: ['./drink.component.css']
})
export class DrinkComponent implements OnInit {
  dataSource: DrinkModel[];
  
  constructor(private dbService: FirebaseService) { }

  ngOnInit(): void {
    this.dbService.getDrinkList().snapshotChanges()
      .subscribe( item => {
        this.dataSource = [];
        item.forEach( element => {
          let x = element.payload.toJSON();
          x['key'] = element.key;
          this.dataSource.push(x as DrinkModel);
        })
    });
  }
  

}
