<div class="custom-form">
    <div class="flex-container text-center" appResizeDialog>
        <h1>Contacta con nosotros</h1>
    </div>
    
    <form novalidate #form="ngForm" (ngSubmit)="onSubmit(form.value)">
        <div class="text-center ">
            <mat-label>Nombre de contacto</mat-label>
            <input #name class="form-control text-center" ngModel matInput required name="name"/>
        </div>

        <div class="text-center">
            <mat-label>Correo de contacto</mat-label>
            <input #email class="form-control text-center" ngModel matInput required name="email"/>
        </div>

        <div class="text-center">
                <mat-label>Asunto</mat-label>
                <input #subject style="text-decoration: underline;" ngModel class="form-control text-center" matInput required name="subject"/>
        </div>

        <div class="text-center">
                <mat-label>Mensaje</mat-label>
                <textarea #message class="form-control" ngModel matInput required name="message" rows=8></textarea>
        </div>

        <div class="text-center">
            <button mat-raised-button color="accent" [disabled]="!subject.value || !message.value || !email.value || !name.value" type="submit">Enviar</button>
        </div>
        
    </form>
</div>