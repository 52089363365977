<div>
    <h1 class="titulos">AVISO LEGAL</h1>
<div class="textos">
    <p>El Romero es titular de la presente página web (elromerodegaleras.com) dedicada a la restauración. A continuación, se indica la información básica de nuestra empresa:</p>
    <ul>
        <li>Denomicación Social: Álvaro Romero Maneiro </li>
        <li>NIF: 45848570W </li>
        <li>Domicilio social: Urb. Agro Novo 105, 15280, Brión (A Coruña) </li>
        <li>Teléfono de contacto: 881817572 </li>
        <li>Correo electrónico de contacto: elromerodegaleras@gmail.com </li>
    </ul>
    Álvaro Romero Maneiro actúa igualmente como responsable de los contenidos de la página web elromerodegaleras.com y de la Política Comercial del Establecimiento.
</div>
<div class="textos">
<h1 class="titulos">POLÍTICA DE PRIVACIDAD Y COMERCIAL</h1>

<h2 class="titulos">Identidad del responsable del tratamiento</h2>
<ul>
    <li>Denomicación Social: Álvaro Romero Maneiro (en adelante, 'El Romero')</li>
    <li>Domicilio social: Urb. Agro Novo 105, 15280, Brión (A Coruña) </li>
    <li>Teléfono de contacto: 881817572 </li>
    <li>Correo electrónico de contacto: <a href="elromerodegaleras@gmail.com">elromerodegaleras@gmail.com</a> </li>
</ul>

<h2 class="titulos">Datos personales objeto de tratamiento</h2>
El Romero recogerá y tratará los datos identificativos, de contacto y de salud (información
de alérgenos) facilitados por los usuarios a través del formulario de petición de reserva.
A través del uso y navegación en esta web, El Romero no recoge ni utiliza ningún otro
dato personal, ni utiliza cookies para el funcionamiento o análisis de la página.

<h2 class="titulos">Finalidad y base jurídica para el tratamiento de los datos personales</h2>
El Romero tratará los datos personales de los usuarios con la única finalidad de atender
su petición de reserva en nuestro restaurante y, en su caso, ponernos en contacto para
gestionar adecuadamente su visita.
La base jurídica del tratamiento de sus datos personales, de acuerdo con la normativa
vigente, es el consentimiento de los titulares, otorgado por lo mismos tras obtener la
información básica exigida legalmente y remitirnos el correspondiente formulario web.
El hecho de no facilitar los datos solicitados e identificados como obligatorios (marcados
con un asterisco*) implicará la imposibilidad de tramitar de manera efectiva su reserva en
El Romero.

<h2 class="titulos">Periodo de conservación de los datos</h2>
Los datos personales proporcionados por los usuarios a través de nuestra página web
serán conservados durante el tiempo necesario para cumplir de manera efectiva con la
finalidad del tratamiento.
Así, una vez se haya hecho efectiva la reserva en nuestro restaurante, sus datos serán
eliminados en un plazo de un mes.
<h2 class="titulos">Destinatarios de los datos personales y transferencias internacionales de datos</h2>
Sus datos únicamente serán comunicados, en la medida en que dicha comunicación sea
necesaria u obligatoria, de acuerdo con la legislación vigente.
Además, no se ha previsto la realización de transferencias internacionales de los datos
personales tratados por El Romero.

<h2 class="titulos">Ejercicio de los derechos de protección de datos</h2>
Le informamos de que, en cualquier momento, los usuarios podrán ejercer los derechos
reconocidos a todas las personas en materia de protección de datos:
<ul>
<li>Acceso: permite al usuario conocer qué información se tiene, de dónde se ha
obtenido, a quién se ha facilitado y con qué usos se ha tratado.</li>
<li>Rectificación: permite al usuario rectificar algún dato erróneo o desactualizado.</li>
<li>Supresión: permite al usuario conseguir que se dejen de tratar sus datos.</li>
<li>Limitación: permite al usuario restringir el tratamiento de sus datos, pero de forma
que sean conservados para alguna finalidad posterior.</li>
<li>Portabilidad: permite al usuario obtener una copia de sus datos en forma
electrónica y, en determinadas circunstancias, solicitar que sean comunicados a
otro prestador de servicios.</li>
<li>Retirar el consentimiento, en cualquier momento, respecto de tratamiento de sus
datos personales, sin que ello afecta a la licitud del mismo.</li>
<li>Presentar una reclamación ante la Agencia Española de Protección de Datos,
a través de su página web <a href="www.aepd.es">www.aepd.es</a>.</li>
</ul>
Para el ejercicio de los derechos indicados, el usuario podrá remitir su solicitud a El
Romero a través de la siguiente dirección de correo
electrónico <a href="elromerodegaleras@gmail.com">elromerodegaleras@gmail.com</a>.

<h2 class="titulos">Modificación de la Política de Privacidad</h2>
El Romero se reserva el derecho a modificar la presente Política de Privacidad, siempre
en los términos permitidos por la legislación vigente y previa comunicación a los
interesados y usuarios mediante su publicación en esta página web.
Fecha de actualización de la Política de Privacidad: 17 de agosto de 2020.
</div>
</div>